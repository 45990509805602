import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import { graphql } from 'gatsby'

import styles from "./about.module.scss";

export default ({ data }) => {
  return (
    <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <title>About | {data.site.siteMetadata.title}</title>
      </Helmet>

        {data.allWordpressPage.edges.map(({ node }) => (
          <div className={styles.about}>
            <h1>{node.title}</h1>

            <div className={styles.content} dangerouslySetInnerHTML={{__html: node.content }} />
          </div>
        ))}

    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        desc,
        intro
      }
    },
    allWordpressPage(
      filter: { slug:{ eq: "about-us" }}
    ) {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
  }
`
